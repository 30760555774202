import { useEffect, useState } from "react"
import MainScreen from "../main-screen"
import Cookies from 'js-cookie'
import Popups from "../popups"
import Politic from "../politic/politic"
const Router = () => {
    const [params, setParams] = useState({})

    const [form, setForm] = useState({})
    const [showPolitic, setShowPolitics] = useState()


    useEffect(() => {
        try {
            Cookies.set('city_id', domainSettings.city.portal_id)
        } catch (e) { }
        domainSettings.domain.title = domainSettings.title
        setParams(domainSettings.domain)
        if (domainSettings.domain.yandex != "") {
            try {
                setTimeout(()=>{
                    try {
                        ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                            domainSettings.domain.clientID = clientID
                        })
                    } catch(e) {}
                },3000)
            } catch (e) {
                console.log(e)
             }
        }
    }, [])

    
    return <div className="container_main">
        {showPolitic?<Politic site={domainSettings.domain.url} date={domainSettings.domain.politicsDate!=""?domainSettings.domain.politicsDate:domainSettings.domain.created_at.split(" ")[0]} close={()=>{setShowPolitics(false)}}/>:<></>}
        <MainScreen params={params} form={form} setForm={setForm} showPolitic={() => setShowPolitics(true)} />
        <Popups params={params} />
    </div>
}

export default Router