import "./popups.css"
import InputMask from 'react-input-mask'
import { useState } from "react"
import { SendData } from "../../utils/send-data"

const Popups = ({ params }) => {
  const [form, setForm] = useState({})
  const [phone, setPhone] = useState("")
  const [error, setError] = useState(false)
  const closePopup = (e) => {
    e.preventDefault()
    document.querySelector('body').classList.remove('overflow')
    document.querySelectorAll('.pu_inner').forEach((popup) => {
      popup.style.display = "none"
    })
    document.querySelector('.pu_rgba').style.display = "none"
  }

  const inputChange = (event) => {
    if (event.target.name === "phone") {
      setPhone(event.target.value)
    }
    setForm(() => {
      return {
        ...form,
        [event.target.name]: event.target.value
      }
    })
  }

  const sendForm = (e) => {
    e.preventDefault()

    if (form.phone.length !== 18) {
      setError(true)
      return
    }
    setError(false)
    let celtype = "getCall"

    SendData(form, () => { }, () => {
      document.querySelectorAll('.pu_inner').forEach((popup) => {
        popup.style.display = "none"
      })
      document.querySelector('.pu_thanks').style.display = "block"
    }, celtype, "", "")
  }

  return (<div className="pu_rgba plr" id="popup_rgba" style={{ display: "none" }}>
    <div className="pu_table">
      <div className="pu_cell">
        <div className="pu_inner pu_call" style={{ display: "none" }}>
          <div className="closeform" onClick={closePopup}><img src="/templates/catalog_template/build/img/closeform.webp" /></div>
          <div className="pu_title" >
            Заказать звонок
            <span>
              Эксперт проекта свяжется с вами в первую же свободную минуту и ответит на все вопросы.
            </span>
          </div>
          <div className="pu_form">
            <InputMask mask="+7\ (999) 999-99-99" onChange={inputChange} value={phone} maskChar={null} >
              {(inputProps) => <input className={error ? "in_style ym-record-keys err" : "in_style ym-record-keys"} name="phone"  {...inputProps} placeholder="+7 ( ___ ) ___ - __ - __" />}
            </InputMask>
            <button className="btn_main" onClick={sendForm}>заказать звонок</button>
          </div>
        </div>
        <div className="pu_inner pu_thanks" style={{ display: "none" }}>
          <div className="closeform" onClick={closePopup}><img src="/templates/catalog_template/build/img/closeform.webp" /></div>
          <div className="pu_title">
            Спасибо!
            <span>
              Наш менеджер скоро с вами свяжется
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Popups