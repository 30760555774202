import "./politic.css"

export const Politic = ({site, date, close}) => {
    return <div className="pu_rgba">
        <div className="pu_table">
            <div className="pu_cell">
                <div className="pu_politic">
                    <div className="closeform" onClick={()=>close()}><img src="/file-store/closeform.svg" /></div>
                    <div className="pu_p_title">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</div>
                    <div className="pu_p_head">
                        <span>г. Краснодар</span>
                        <span>{date}</span>
                    </div>
                    <div className="pu_p_content">
                        <div className="pu_p_title">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</div>
                        <p>
                            1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
                        <p>
                            1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом, действующие от имени ООО «Лидген», которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. </p>
                        <p>
                            1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных), а также аудиозаписи диалога Пользователя с оператором сервиса. </p>
                        <p>
                            1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, передача, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                        </p>
                        <p>
                            1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания. 1.1.5. «Пользователь сайта (далее - Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
                        </p>
                        <p>
                            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный вебсервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта. </p>
                        <p>
                            1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
                            построенной по протоколу IP.
                        </p>

                        <div className="pu_p_title">2. ОБЩИЕ ПОЛОЖЕНИЯ</div>

                        <p>
                            2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных
                            данных Пользователя.
                        </p>
                        <p>
                            2.2. В случае несогласия с условиями Политики конфиденциальности
                            Пользователь должен прекратить использование сайта.
                        </p>
                        <p>
                            2.3. Настоящая Политика конфиденциальности применяется только к сайту "{site}". Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте. </p>
                        <p>
                            2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.
                        </p>

                        <div className="pu_p_title">3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</div>

                        <p>
                            3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению
                            режима защиты конфиденциальности персональных данных, которые
                            Пользователь предоставляет по запросу Администрации сайта при подаче
                            заявки с сайта.
                        </p>
                        <p>
                            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения формы на Сайте "{site}" и включают в себя следующую информацию:
                        </p>
                        <p>
                            3.2.1. имя Пользователя;
                        </p>
                        <p>
                            3.2.2. контактный телефон Пользователя;
                        </p>
                        <p>
                            3.2.3. адрес электронной почты (e-mail);
                        </p>
                        <p>
                            3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлены статистические скрипты систем ("Яндекс.Метрика", "Google Analytics", "Roistat"):
                        </p>
                        <ul>
                            <li>IP адрес;</li>
                            <li>информация из cookies;</li>
                            <li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
                            <li>время доступа;</li>
                            <li>адрес страницы, на которой расположен рекламный блок;</li>
                            <li>реферер (адрес предыдущей страницы).</li>
                        </ul>

                        <div className="pu_p_title">4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</div>

                        <p>
                            4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:
                        </p>
                        <p>
                            4.1.1. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, звонков, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.
                        </p>
                        <p>
                            4.1.2. Предоставления персональных данных Пользователя третьим лицам, имеющим договор с ООО «Лидген», для оказания услуг Пользователю.
                        </p>
                        <p>
                            4.1.3. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.
                        </p>
                        <p>
                            4.1.4. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки с сайта {site} и иных сведений от имени Сайта.
                        </p>
                        <p>
                            4.1.5. Осуществления рекламной деятельности с согласия Пользователя.
                        </p>

                        <div className="pu_p_title">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</div>

                        <p>
                            5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств
                        </p>
                        <p>
                            5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                        </p>
                        <p>
                            5.3. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.
                        </p>
                        <p>
                            5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                        </p>
                        <p>
                            5.5. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                        </p>


                        <div className="pu_p_title">6. ОБЯЗАТЕЛЬСТВА СТОРОН</div>


                        <p>
                            <b>6.1. Пользователь обязан:</b>
                        </p>
                        <p>
                            6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
                        </p>
                        <p>
                            6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
                        </p>
                        <p>
                            <b>6.2. Администрация сайта обязана:</b>
                        </p>
                        <p>
                            6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
                        </p>
                        <p>
                            6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                        </p>
                        <p>
                            6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
                        </p>
                        <p>
                            6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
                        </p>
                        <div className="pu_p_title">7. ОТВЕТСТВЕННОСТЬ СТОРОН</div>
                        <p>
                            7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2, 5.3. и 7.2. настоящей Политики Конфиденциальности.
                        </p>
                        <p>
                            7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
                        </p>
                        <p>
                            7.2.1. Стала публичным достоянием до её утраты или разглашения.
                        </p>
                        <p>
                            7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
                        </p>
                        <p>
                            7.2.3. Была разглашена с согласия Пользователя.
                        </p>
                        <div className="pu_p_title">8. РАЗРЕШЕНИЕ СПОРОВ</div>
                        <p>
                            8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
                        </p>
                        <p>
                            8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
                        </p>
                        <p>
                            8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.
                        </p>
                        <p>
                            8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.
                        </p>
                        <div className="pu_p_title">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</div>
                        <p>
                            9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                        </p>
                        <p>
                            9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                        </p>
                        <p>
                            9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу электронной почты info@g-n.ru.
                        </p>
                        <p>
                            9.4. Действующая Политика конфиденциальности размещена на странице по адресу https://{site}
                        </p>
                        <br /><br />
                        <p>
                            Обновлено {date}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Politic