import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask'
import { SendData } from "../../utils"
import parse from 'html-react-parser'
import { phoneClickDataLayer, startQuizDataLayer } from '../../utils/send-data';
import axios from 'axios';

const MainScreen = ({ params, form, setForm, nextStep, showPolitic }) => {
  const [phone, setPhone] = useState("")
  const [blocked, setBlocked] = useState(false)
  const [error, setError] = useState(false)
  const [cityPrices, setCityPrices] = useState([])

  const isMobile = () => {
    let check = false;
    if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
      check = true
    }
    return check;
  }

  const getCityPrices = async (cityID) => {
    let url = "/jks/prices/" + domainSettings.city.id
    axios({
      method: "get",
      url: url,
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
    }).then((data) => {
      console.log(data)
      let prices = {}
      data.data.forEach((price) => {
        if (!price.rooms) {
          prices[0] = price.price
        } else {
          prices[price.rooms] = price.price
        }
      })
      console.log(prices[0])
      setCityPrices(prices)
    })
  }

  const inputChange = (event) => {
    let value = event.target.value

    if (event.target.name === "phone") {
      if (value.length > 4 && value.slice(0, 5) != "+7 (9") {
        value = "+7 ("
      }
      setPhone(value)
      setForm({
        ...form,
        phone: value
      })
    } else {
      setForm({
        ...form,
        Комментарий: event.target.value
      })
    }
  }

  const getBackground = () => {
    if (isMobile()) {
      return domainSettings.domain.background_mobile
    }
    return domainSettings.domain.background
  }

  const sendClick = (event) => {
    event.preventDefault()

    if (blocked) {
      return
    }
    if (phone.length !== 18) {
      setError(true)
      return
    }

    setError(false)
    if (domainSettings.domain.validate_phone) {
      setBlocked(true)
      fetch("/lead/validator/" + form.phone)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.valid) {
            SendData(form, setForm, () => { showSuccess(); downloadPdf() }, "getForm")
          } else {
            setBlocked(false)
            SendData(form, setForm, () => { showSuccess(); downloadPdf() }, "getForm", true)
          }
        });
    } else {
      SendData(form, setForm, () => { showSuccess(); downloadPdf() }, "getForm")
    }

  }

  const getParam = (p) => {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  const downloadPdf = () => {
    let filter = domainSettings.domain.pdfParams[0]
    domainSettings.domain.pdfParams.forEach((param) => {
      if (getParam(param.urlParam) === param.urlValue) {
        filter = param
      }
    })
    let data = {
      cityId: domainSettings.domain.city_id,
      ...filter
    }


    fetch('/jks/pdf', { method: 'POST', body: JSON.stringify(data) })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let filename = "Каталог.pdf";
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  const showPopup = () => {

    document.querySelector('body').classList.add('overflow')
    document.querySelectorAll('.popups').forEach((popup) => {
      popup.style.display = "none"
    })
    document.querySelector('.pu_rgba').style.display = "block"
    document.querySelector('.pu_call').style.display = "block"
  }

  const showSuccess = () => {

    document.querySelector('body').classList.add('overflow')
    document.querySelectorAll('.popups').forEach((popup) => {
      popup.style.display = "none"
    })
    document.querySelector('.pu_rgba').style.display = "block"
    document.querySelector('.pu_thanks').style.display = "block"
  }

  const getFormattedDay = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '.' + mm + '.' + yyyy;
  }

  const getMinimalPrice = () => {
    let price = (Math.round(cityPrices[0]/100000))/10
    return price
  }

  useEffect(() => {
    getCityPrices()
  }, [])


  return (
    <div className="header plr" style={{ background: params.background != "" ? `url("/file-store/${getBackground() != "" ? getBackground() : params.background}") center / cover no-repeat` : `url("/templates/catalog_template/build/img/header_bg.webp") center / cover no-repeat` }}>
      <div className="wmain">
        <div className="hl_top">
          {/*<div className="logo">
            <img src="/templates/catalog_template/build/img/logo.svg" />
            <span>{!domainSettings.title || domainSettings.title.length === 0 ? parse("Каталог новостроек Москвы <br/> на старте продаж") : parse(domainSettings.title)}</span>
          </div>*/}
          <div></div>
          <div className="hl_top_r">
            <div className="hl_manager">
              <div className="hl_manager_info">
                <a href="tel:+78006005523">+7 (800) 600-55-23</a>
                <div className="hl_call" onClick={showPopup}>Заказать звонок</div>
              </div>
              <div className="hl_manager_ava"><img src="/templates/catalog_template/build/img/operator.jpg" /></div>
            </div>
          </div>
        </div>
        <h1>
          {!domainSettings.title || domainSettings.title.length === 0 ? parse("Каталог новостроек Москвы <br/> на старте продаж") : parse(domainSettings.title)}
        </h1>
        <ul className="head_info">
          <li>от {cityPrices[0] ? getMinimalPrice() : ""} млн. ₽</li>
          <li>10 новостроек</li>
          <li>для жизни и инвестиций</li>
        </ul>
        <form className="head_form">
          <div className="head_form_l">
            <div className="head_form_title">
              Скачайте каталог новостроек на старте продаж
            </div>
            <div className="head_form_inner">
              <InputMask mask="+7\ (999) 999-99-99" name="phone" value={phone} onChange={inputChange} maskChar={null} >
                {(inputProps) => <input className={error ? "in_style ym-record-keys err" : "in_style ym-record-keys"} name="phone"  {...inputProps} placeholder="+7 ( ___ ) ___ - __ - __" />}
              </InputMask>
              <button className="btn_main" onClick={sendClick}>скачать</button>
            </div>
            <div className="head_form_info">
              <span>Время скачивания: 5 секунд</span>
              <span>PDF, 2 MB</span>
              <span>Обновлен {getFormattedDay()}</span>
            </div>
          </div>
          <div className="head_form_r"><img src="/templates/catalog_template/build/img/head_img.webp" /></div>
        </form>
        <div className="footer">
          <div className="footer_l">
            <a className="f_politic" href="#" onClick={(e)=>{e.preventDefault(); showPolitic()}}>Политика конфиденциальности</a>
          </div>
          <div className="footer_c">
            {/*<div className="f_soc">
              <a href="#"><img src="/templates/catalog_template/build/img/whatsapp.svg" /></a>
              <a href="#"><img src="/templates/catalog_template/build/img/telegram.svg" /></a>
            </div>*/}
          </div>
          <div className="footer_r">
            Проект компании Leadactiv<span>|</span>{(new Date().getFullYear())}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;